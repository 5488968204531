class HeroVideo {
    constructor() {

    }
    init(callback) {
        const attrName = 'data-hero-video';

        // Elements
		const videoContainers = document.querySelectorAll('[' + attrName + ']');
        const totalVideos = videoContainers.length;
        let loadedVideos = 0;

        console.log('Hero video: init');

        videoContainers.forEach((videoContainer) => {
            console.log({videoContainer});
            const videoSrc = videoContainer.getAttribute(attrName);

            const videoElement = document.createElement('video');
            videoElement.src = videoSrc;

            // Add classes to the video element
            videoElement.className = videoContainer.dataset.classes || '';

            // Add video attrs
            videoElement.preload = 'auto';
            videoElement.loop = true;
            videoElement.muted = true;
            videoElement.playsinline = true;

            videoElement.addEventListener('loadeddata', () => {
                loadedVideos++;

                // Check if all videos have finished loading
                if (loadedVideos === totalVideos && typeof callback === 'function') {
                    callback();
                }
            });

            videoContainer.appendChild(videoElement);
        });
    }
}

export default new HeroVideo();
