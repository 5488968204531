import env from '@js/utils/env';
import gsap from "gsap";

const HTML_CLASSLIST = document.documentElement.classList;

class Header {
    constructor() {

    }
    start() {
        this.scrollPast = 150;
        this.shouldAnimate = false;
        this.heroEl = document.querySelector('.hero');


        // If hero element use this as the target to scroll past
        if (this.heroEl) {
            this.scrollPast = (this.heroEl.offsetHeight + this.heroEl.offsetTop);
            this.shouldAnimate = true;
        }
        else {
            let animateHeaderAttr = 'data-spa-header-animate',
                spa = document.querySelector('[' + animateHeaderAttr + ']'),
                headerAnimate = spa.getAttribute(animateHeaderAttr);

            if (headerAnimate) {
                this.shouldAnimate = true;
            }
        }

        if (this.shouldAnimate) {
            this.headerEl = document.querySelector('.mn-hdr');
            this.currentHeaderStyle = this.headerEl.getAttribute('data-style');
            this.currentHeaderEl = this.headerEl.querySelector('.' + this.currentHeaderStyle);
            this.newHeaderEl = this.headerEl.querySelector('.title');
            this.isPast = false;
            this.run = false;

            this._init();
        }
    }
    _init() {
        this.scrollTop = window.pageYOffset || document.body.scrollTop;

        this._manage();

        window.addEventListener('scroll', (e) => {
            if (this.shouldAnimate) {
                const scrollTop = window.pageYOffset || document.body.scrollTop;
                this.scrollTop = scrollTop < 0 ? 0 : scrollTop;
                this._manage();
            }
        }, env.havePassive ? { passive: true } : false);

        window.addEventListener('resize', (e) => {
            if (this.shouldAnimate) {
                this._manage();
            }
        });
    }
    _manage() {
        if (HTML_CLASSLIST.contains('_page-home')) {
            this.fromEl = this.newHeaderEl;
            this.toEl = this.currentHeaderEl;

            gsap.to(this.fromEl, {
                duration: .4,
                display: 'none',
                alpha: 0,
                onComplete: () => {
                    gsap.to(this.toEl, {
                        duration: .4,
                        display: 'block',
                        alpha: 1,
                    });
                }
            });
        }
        else {
            // Scroll past height
            if (this.scrollTop >= this.scrollPast) {
                if (!this.isPast) {
                    this.isPast = true;

                    this.fromEl = this.currentHeaderEl;
                    this.toEl = this.newHeaderEl;

                    this.run = true;
                }
            } else {
                if (this.isPast) {
                    this.isPast = false;

                    this.fromEl = this.newHeaderEl;
                    this.toEl = this.currentHeaderEl;

                    this.run = true;
                }
            }

            if (this.run) {
                this.run = false;

                gsap.to(this.fromEl, {
                    duration: .4,
                    display: 'none',
                    alpha: 0,
                    onComplete: () => {
                        gsap.to(this.toEl, {
                            duration: .4,
                            display: 'block',
                            alpha: 1,
                        });
                    }
                });
            }
        }
    }
    leave() {
        this.heroEl = null;
    }
}

export default new Header();
