// Imports
import { gsap, Power3, Power2 } from "gsap";
import { Observer } from "gsap/Observer";
gsap.registerPlugin(Observer);

const SCROLL_ANIMATE = false;
const ANIMATION_DURATION = 4;
let ANIMATING = false;

class Lifestyle {
	constructor(container) {
        // set css var for mask angle to 0
        gsap.set('html', {'--maskAngle': '0deg'});

        const fanOpenTimeline = () => {
            ANIMATING = true;

            const tl = gsap.timeline({
                delay: SCROLL_ANIMATE ? 0 : .25,
                paused: SCROLL_ANIMATE ? true : false,
                defaults: {
                    duration: ANIMATION_DURATION+1,
                    ease: Power3.easeOut,
                },
                onComplete: () => {
                    ANIMATING = false;
                }
            });

            tl.to('.hero-heading', {
                opacity: 0,
                duration: ANIMATION_DURATION,
                ease: Power3.easeOut
            });
            tl.to('html', {
                '--maskAngle': '-270deg',
                duration: ANIMATION_DURATION,
                ease: Power2.easeOut
            }, '<');
            tl.to('.fanblade', {
                duration: ANIMATION_DURATION+1,
                rotation: -65,
                alpha: 0,
                ease: Power3.easeOut
            }, '<');

            return tl;
        }

        const fanCloseTimeline = () => {
            ANIMATING = true;

            const tl = gsap.timeline({
                paused: true,
                defaults: {
                    duration: ANIMATION_DURATION+1,
                    ease: Power3.easeOut,
                },
                onComplete: () => {
                    ANIMATING = false;
                }
            });

            tl.to('.hero-heading', {
                alpha: 1,
                duration: ANIMATION_DURATION,
                ease: Power3.easeOut
            });
            tl.to('html', {
                '--maskAngle': '0deg',
                duration: ANIMATION_DURATION,
                ease: Power2.easeOut
            }, '<');

            const fanblades = document.querySelectorAll('.fanblade');
            const rotations = fanRotations();

            for(let i=0; i<fanblades.length; i++) {
                tl.to(fanblades[i], {
                    duration: ANIMATION_DURATION+1,
                    rotation: rotations[i],
                    alpha: 1,
                    ease: Power3.easeOut
                }, '<');
            }

            return tl;
        }

        const fanRotations = () => {
            const numBlades = document.querySelectorAll('.fanblade').length;
            const w = window.innerWidth;
            let rotations = [];

            for(let i=0; i<numBlades; i++) {
                const mobileAdjustment = w < 600 ? 20 : w < 900 ? 10 : 0; // ((w / h) * 60) - 30;
                const rotation = ((i - 2) * 12) + mobileAdjustment;

                rotations.push(rotation);
            }

            return rotations;
        };

        const setInitialFanBladeRotation = () => {
            const fanblades = document.querySelectorAll('.fanblade');
            // Set rotation of each fanblade
            const rotations = fanRotations();

            fanblades.forEach((fanblade, index) => {
                fanblade.style.transform = `rotate(${rotations[index]}deg)`;
            });
        };

        // is window at top
        const isAtTop = () => {
            return window.scrollY === 0;
        };

        setInitialFanBladeRotation();

        if (!SCROLL_ANIMATE) {
            fanOpenTimeline();
        }

        window.addEventListener('resize', () => {
            setInitialFanBladeRotation();
        });

        if (SCROLL_ANIMATE) {
            let openTL = null,
                closeTL = null;

            Observer.create({
                type: "wheel,touch,scroll,pointer",
                //wheelSpeed: -1,
                onDown: () => {
                    if (closeTL !== null) {
                        closeTL.kill();
                        closeTL = null;
                    }
                    if (openTL === null) {
                        openTL = fanOpenTimeline();
                        openTL.play();
                    }
                },
                onUp: () => {
                    // don't show header again unless at top
                    if (!isAtTop()) {
                        return;
                    }

                    if (openTL !== null) {
                        openTL.kill();
                        openTL = null;
                    }
                    if (closeTL === null) {
                        closeTL = fanCloseTimeline();
                        closeTL.play();
                    }

                    // console.log('up');
                    // // log headerAnim progress
                    // console.log(headerAnim.totalTime);

                    // // prevent delay when reversing animation from the end-position
                    // if (headerAnim.totalTime() > 1.5) {
                    //     headerAnim.seek(1.5);
                    // }
                    // headerAnim.reversed(true);
                    // //headerAnim.play();
                },
                tolerance: 0,
            });
        }



        // const fanblades = document.querySelectorAll('.fanblade');
        //
        // // Set rotation of each fanblade
        // fanblades.forEach((fanblade, index) => {
        //     fanblade.style.transform = `rotate(${(index - 2) * 12}deg)`;
        // });
        // // Set CSS var for mask angle to 0
        // gsap.set('html', {'--maskAngle': '0deg'});
        //
        // const headerAnim = gsap.timeline({
        //     paused: SCROLL_ANIMATE,
        //     delay: SCROLL_ANIMATE ? 0 : .2,
        //     defaults: {
        //         duration: ANIMATION_DURATION,
        //         ease: Power3.easeOut,
        //     }
        // });
        // headerAnim.to('.hero-heading', {
        //     opacity: 0,
        //     duration: ANIMATION_DURATION,
        //     ease: Power3.easeOut
        // });
        // headerAnim.to('html', {
        //     '--maskAngle': '-270deg',
        //     duration: ANIMATION_DURATION,
        //     ease: Power2.easeOut
        // }, '<');
        // headerAnim.to('.fanblade', {
        //     rotation: -65,
        //     alpha: 0,
        //     duration: ANIMATION_DURATION + 1,
        //     ease: Power3.easeOut
        // }, '<');
        // headerAnim.endTime();
        //
        // if (SCROLL_ANIMATE) {
        //     // is window at top
        //     const isAtTop = () => {
        //         return window.scrollY === 0;
        //     };
        //
        //     Observer.create({
        //         type: "wheel,touch,scroll,pointer",
        //         //wheelSpeed: -1,
        //         onDown: () => {
        //             headerAnim.reversed(false);
        //             headerAnim.play();
        //         },
        //         onUp: () => {
        //             // don't show header again unless at top
        //             if (!isAtTop()) {
        //                 return;
        //             }
        //
        //             console.log('up');
        //
        //             // Prevent delay when reversing animation from the end-position
        //             if (headerAnim.totalTime() > 1.5) {
        //                 headerAnim.seek(1.5);
        //             }
        //             headerAnim.reversed(true);
        //             //headerAnim.play();
        //         },
        //         tolerance: 0,
        //     });
        // }
	}
}

export default Lifestyle
