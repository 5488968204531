class Contact {
	constructor(container) {
		const contactMapEl = document.getElementById('contact-map');

        if (contactMapEl) {
            const scriptPromise = new Promise((resolve, reject) => {
                const script = document.createElement('script');

                document.body.appendChild(script);
                script.onload = resolve;
                script.onerror = reject;
                script.async = true;
                script.src = 'https://cdn.jsdelivr.net/npm/mapbox-gl@2.9.1/dist/mapbox-gl.min.js';
            });

            scriptPromise.then(() => {
                mapboxgl.accessToken = 'pk.eyJ1IjoidGhpcnN0IiwiYSI6ImNpcjd5bWd3bzAwd25nYW05dDNidGlrbGYifQ.LrGqviBMxmi5v4a4HI2RaQ';
                const contactMap = new mapboxgl.Map({
                    container: 'contact-map',
                    style: 'mapbox://styles/thirst/cl39407kk001c15pfr1ork3pq?optimize=true',
                    interactive: false
                });

                contactMap.scrollZoom.disable();
            });
        }
	}
	leave(){

	}
}

export default Contact;
