// Imports
import ImageCompare from 'image-compare-viewer';
import LazyLoad from 'vanilla-lazyload';
import MicroModal from 'micromodal';
import reframe from 'reframe.js';
import Splide from '@splidejs/splide';
import { lock, unlock } from 'tua-body-scroll-lock';

// Project imports
// import FloorplanHover from '@js/modules/FloorplanHover';
import FloorplanImagesPreload from '@js/modules/FloorplanImagesPreload';
import Header from '@js/modules/Header';
import HeroVideo from '@js/modules/HeroVideo';
import Navigation from '@js/modules/Navigation';

class Page {
    constructor() {

    }
    init() {
        /**
         * Get battery charge level
         */
        let goodBatteryCharge = true;
        if ('getBattery' in navigator) {
            navigator.getBattery().then(battery => {
                // If battery is less than 10%
                if (battery.level < 0.10) {
                    goodBatteryCharge = false;
                }
            });
        }

        // External links
        window.CARETTA.modules.ExternalLinks.build();

        /**
         * Open external links in new tab
         */
        const extLinks = document.querySelectorAll('[data-external]');
        if (extLinks) {
            extLinks.forEach((item, i) => {
                item.addEventListener('click', (e) => {
                    let url = item.getAttribute('href');
                    window.open(url, '_blank').focus();
                });
            });
        }

        /**
         * Lazy load
         */
        let lazyLoadInstance = new LazyLoad({
            threshold: 400
        });

        /**
         * Reframe
         */
        reframe('.reframe');

        /**
         * Header
         */
        Header.start();

        /**
         * Hero video
         */
        function videosLoadedCallback() {
            console.log('All videos have finished loading!');

            // Detect if motion is allowed and the device battery is charged enough
            if (!window.CARETTA.env.reducedMotion && goodBatteryCharge) {
                const videoHeroEls = document.querySelectorAll('.hero-video');

                videoHeroEls.forEach((video) => {
                    // Test for support for readyState, else fallback to canplay event
                    if (video.readyState >= video.HAVE_FUTURE_DATA) {
                        // Show video
                        video.classList.remove('opacity-0');
                        // Play video
                        video.play();
                    }
                    else {
                        video.addEventListener('canplay', () => {
                            // Show video
                            video.classList.remove('opacity-0');
                            // Play video
                            video.play();
                        }, false);
                    }
                });
            }
        }

        HeroVideo.init(videosLoadedCallback);

        /**
         * Floor plans Hover
         */
        // FloorplanHover.start();

        /**
         * Preload floor plan images
         */
        FloorplanImagesPreload.start();

        /**
         * Modals
         */
        MicroModal.init({
            onShow: (modal, trigger, event) => {
                let trig = window.CARETTA.env.isSafari ? event.target : trigger,
                    type = trig.getAttribute('data-modal-type'),
                    headingContent = null,
                    content = null;

                // Lock scroll
                lock(modal);

                // Add open class to HTML element
                document.documentElement.classList.add(window.CARETTA.helpers.ModalOpenClass);

                // Check the type and add content
                if (type === 'view') {
                    let image = trig.getAttribute('data-image');

                    if (image) {
                        content = '<div class="rotate-message-content"><img class="h-full w-auto" src="' + image + '"></div>';
                    }
                    else {
                        let imgMobile = trig.getAttribute('data-image-mobile'),
                            imgDesktop = trig.getAttribute('data-image-desktop');

                        content = '<div class="rotate-message-content"><img class="object-contain mx-auto h-full w-full" src="' + imgMobile + '" srcset="' + imgDesktop + ' 1024w" sizes="auto"></div>';
                    }

                    content += '<div class="rotate-message"><h2 class="h2">Rotate to view</h2></div>';
                }
                else if (type === 'tour') {
                    modal.classList.add('_full', '_light');

                    let heading = trig.getAttribute('data-heading'),
                        url = trig.getAttribute('data-url');

                    headingContent = heading;
                    content = '<iframe class="h-full w-full" src="' + url + '" frameborder="0"></iframe>';
                }

                // Modal content
                modal.querySelector('.modal-content').innerHTML = content;
            },
            onClose: (modal) => {
                // Remove full class from modal
                modal.classList.remove('_full', '_light');

                // Clear modal content
                modal.querySelector('.modal-content').innerHTML = '';

                // Unlock scroll
                unlock(modal);

                // Remove open class to HTML element
                document.documentElement.classList.remove(window.CARETTA.helpers.ModalOpenClass);
            },
            openTrigger: 'data-modal-open',
            closeTrigger: 'data-modal-close',
        });

        /**
         * Image comparison
         */
        const imageCompareEls = document.querySelectorAll('.image-compare');
        imageCompareEls.forEach((element) => {
            let view = new ImageCompare(element).mount();
        });


        /**
         * Sliders
         */
        const carouselEls = document.querySelectorAll('.carousel');
        carouselEls.forEach((item, i) => {
            new Splide(item, {
                arrows: true,
                arrowPath: 'M12.5,38.2l13.6-18.3L12.5,1.6',
                drag: true,
                gap: '1.5em',
                pagination: false,
                perPage: 2,
                type: 'loop',
                breakpoints: {
                    768: {
                        perPage: 1,
                    }
                }
            }).mount();
        });

        // Hero gallery carousels
        const heroGalleryCarouselEls = document.querySelectorAll('.hero-gallery-carousel');
        heroGalleryCarouselEls.forEach((item, i) => {
            const carouselSlides = item.querySelectorAll('.splide__slide');
            const slideCount = carouselSlides.length;

            if (slideCount > 1) {
                // Create carousel
                new Splide(item, {
                    arrows: true,
                    arrowPath: 'M12.5,38.2l13.6-18.3L12.5,1.6',
                    autoplay: true,
                    drag: true,
                    gap: 0,
                    interval: config.heroGalleryInterval || 4000,
                    pagination: true,
                    pauseOnHover: false,
                    pauseOnFocus: false,
                    perPage: 1,
                    rewind: true,
                    type: 'fade'
                }).mount();

                // Add container class to arrows
                const carouselArrows = item.querySelector('.splide__arrows');
                if (carouselArrows) {
                    carouselArrows.classList.add('container');
                }
            }
            else {
                item.classList.add('visible');
            }
        });
    }
}

export default new Page();
