class HeaderHeight {
	constructor() {
        document.addEventListener('DOMContentLoaded', e => {
            this.calulate();
        });

        // We listen to the resize event
        window.addEventListener('resize', () => {
            this.calulate();
        });
	}
    calulate() {
        const headerEl = document.querySelector('.mn-hdr');

        // Get the height of the element
        let hh = headerEl ? headerEl.offsetHeight : 0;

        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--hh', `${hh}px`);
    }
}

export default new HeaderHeight();
