import utils from '@js/utils/utils';

class FloorplanImagesPreload {
    constructor() {

    }
    start() {
        const floorplanImagesPreloadEls = document.querySelectorAll('.floorplans-preload');

        let images = [];

        if (floorplanImagesPreloadEls) {
            floorplanImagesPreloadEls.forEach((item) => {
                let image = item.getAttribute('data-image'),
                    mobileImage = item.getAttribute('data-image-mobile'),
                    desktopImage = item.getAttribute('data-image-desktop');

                if (image) {
                    images.push(image);
                }
                if (mobileImage) {
                    images.push(mobileImage);
                }
                if (desktopImage) {
                    images.push(desktopImage);
                }
            });

            // Preload images
            utils.preloadImages(images);
        }
    }
}

export default new FloorplanImagesPreload();
