/* CSS */
import "@js/parts/css";

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

import barba from '@barba/core';
import gsap from 'gsap';
import htmx from 'htmx.org';

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';

Alpine.plugin(collapse);
Alpine.start();
window.Alpine = Alpine;

// Project utils
import env from '@js/utils/env';
import utils from '@js/utils/utils';

// Project helpers
import DelayedCall from '@js/helpers/DelayedCall';
import VhCalc from '@js/helpers/ViewHeightCalc';
import HeaderHeight from '@js/helpers/HeaderHeight';
import HeaderMessageHeight from '@js/helpers/HeaderMessageHeight';

// Project modules
// import Modal from '@js/modules/Modal';
import PageScrollingManager from '@js/modules/PageScrollingManager';
import PageScrolling from '@js/modules/PageScrolling';
import ScrollTo from '@js/modules/ScrollTo';
import Appear from '@js/modules/Appear';
import AppearParent from '@js/modules/AppearParent';
import ExternalLinks from '@js/modules/ExternalLinks';
import Page from '@js/modules/Page';
import ProgressLine from '@js/modules/ProgressLine';
import Map from '@js/modules/Map';

// Pages modules
import Contact from '@js/pages/Contact';
import Home from '@js/pages/Home';
import Lifestyle from '@js/pages/Lifestyle';


// HTML elememt classlist
const HTML_CLASSLIST = document.documentElement.classList;
const BODY_CLASSLIST = document.body.classList;
const NAV = document.querySelector('.mn-nav');
const HEADEREl = document.querySelector('.mn-hdr');
const PAGE_TRANSITION_SPEED = 0.7;

window.CARETTA =
    window.CARETTA ||
    new (function CARETTA() {
        // Utilities
        this.env = env;
        this.utils = utils;

        // Helpers
        this.helpers = {
            DelayedCall: DelayedCall,
            GoToScrollPos: false,
            ModalOpenClass: '_modal-open',
            ScrollYPos: 0,
            VhCalc: VhCalc,
        };

        // Modules
        this.modules = {
            PageScrollingManager: PageScrollingManager,
            PageScrolling: PageScrolling,
            Appear: Appear,
            AppearParent: AppearParent,
            ExternalLinks:  ExternalLinks,
            Map: Map,
            Page: Page,
            ProgressLine: ProgressLine,
            ScrollTo: ScrollTo,
        };

        // Pages
        this.pages = {
            contact: Contact,
            home: Home,
            lifestyle: Lifestyle,
        };

        const popstateClass = '_popstate';


        let currentController;
        let currentClasses;
        let currentNameSpace;

        const beforeController = (data, done) => {
            console.log('beforeController');
            done();
        };

        const afterLeaveController = (data, done) => {
            console.log('afterLeaveController');
            done();
        };

        const leaveController = (container, done) => {
            HTML_CLASSLIST.add('_page-loading');
            HTML_CLASSLIST.remove('_page-loaded');

            // Stop scroll
            PageScrolling.lock(document.body);

            // Show progress line
            ProgressLine.show();

            // Fade out container
            gsap.to(container, {
                duration: PAGE_TRANSITION_SPEED,
                alpha: 0,
            });

            // Leave the current page if exists
            currentController &&
            currentController.leave &&
            currentController.leave();

            done();
        };

        const enterController = (container, firstCall = false) => {
            // Scroll to top
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: firstCall ? "instant" : "auto",
            });

            // Enable scrolling
            PageScrolling.unlock(document.body);

            // Modules
            ScrollTo.build();

            if (!firstCall) {
                ProgressLine.hide();
            }

            if (!this.env.reducedMotion) {
                Appear.start();
                AppearParent.start();
            }

            PageScrolling.start();
            PageScrollingManager.update(true);

            // Processes new content, enabling htmx behavior
            if (!firstCall) {
                htmx.process(document.body);
            }

            // Vars
            const nameSpace = container.getAttribute('data-spa-namespace');
            const headerStyle = container.getAttribute('data-spa-header');
            const title = container.getAttribute('data-spa-title');
            let bgColor = container.getAttribute('data-spa-bg');
            let navColor = container.getAttribute('data-spa-nav');

            // Default bgColor to light
            if (bgColor === null) {
                bgColor = 'light';
            }

            // Set BG color
            if (bgColor == 'dark') {
                //Dark BG

                // Update body BG
                BODY_CLASSLIST.remove('bg-springwood');
                BODY_CLASSLIST.add('bg-bluewood');

                // Update nav
                NAV.classList.remove('bg-bluewood-gradient', 'text-springwood');
                NAV.classList.add('bg-springwood', 'text-bluewood');

                if (navColor === null) {
                    // Update header
                    HEADEREl.classList.remove('text-bluewood');
                    HEADEREl.classList.add('text-springwood');
                }
            }
            else {
                // Light BG

                // Update body BG
                BODY_CLASSLIST.remove('bg-bluewood');
                BODY_CLASSLIST.add('bg-springwood');

                // Update nav
                NAV.classList.remove('bg-springwood', 'text-bluewood');
                NAV.classList.add('bg-bluewood-gradient', 'text-springwood');

                if (navColor === null) {
                    // Update header
                    HEADEREl.classList.remove('text-springwood');
                    HEADEREl.classList.add('text-bluewood');
                }
            }

            // Set NAV color
            if (navColor != null) {
                if (navColor == 'light') {
                    // Update header
                    HEADEREl.classList.remove('text-bluewood');
                    HEADEREl.classList.add('text-springwood');
                }
                else {
                    // Update header
                    HEADEREl.classList.remove('text-springwood');
                    HEADEREl.classList.add('text-bluewood');
                }
            }

            // Update header
            // Current header
            let currentHeader = HEADEREl.getAttribute('data-style');

            if (title !== null) {
                HEADEREl.querySelector('.title').innerHTML = title;
            }

            if (headerStyle !== currentHeader) {
                let currentHeaderTitle = HEADEREl.querySelector('.'+currentHeader);
                console.log({currentHeaderTitle});

                gsap.to(currentHeaderTitle, {
                    duration: .6,
                    display: 'none',
                    alpha: 0,
                    onComplete: () => {
                        if (headerStyle !== '') {
                            let newHeaderTitle = HEADEREl.querySelector('.'+headerStyle);
                            gsap.to(newHeaderTitle, {
                                duration: .6,
                                display: 'block',
                                alpha: 1,
                            });
                        }
                    }
                });

                // Update header attr
                HEADEREl.setAttribute('data-style', headerStyle);
            }

            // Page controller
            const pageController = this.pages[nameSpace.toLowerCase()];
            if (pageController) {
                // Run page JS
                currentController = new pageController(container);
            }
            else {
                currentController = null;
            }

            // Test for old classes - if any, remove them
            if (currentClasses) {
                currentClasses.forEach(cssClass => {
                    HTML_CLASSLIST.remove(cssClass);
                });
            }
            // Get new classes - if any, add them
            currentClasses = (container.getAttribute('data-spa-class') || null);
            if (currentClasses) {
                currentClasses = currentClasses.split(' ');

                currentClasses.forEach(cssClass => {
                    HTML_CLASSLIST.add(cssClass);
                });
            }

            currentNameSpace && HTML_CLASSLIST.remove(currentNameSpace);
            currentNameSpace = '_page-' + nameSpace;
            HTML_CLASSLIST.add(currentNameSpace);

            HTML_CLASSLIST.remove('_page-loading');
            HTML_CLASSLIST.add('_page-loaded');

            // Update active links on page
            Array.from(document.querySelectorAll('a')).forEach((link) => {
                if (this.utils.testHrefIsActual(link.getAttribute('href'))) {
                    link.classList.add('_active');
                }
                else {
                    link.classList.remove('_active');
                }
            });

            // Mark external links
            this.helpers.DelayedCall.create(1000, () => {
                ExternalLinks.build();
                Map.build();
            });

            if (!firstCall) {
                gsap.from(container, {
                    duration: PAGE_TRANSITION_SPEED,
                    alpha: 0
                });
            }
        };

        const afterController = (next) => {
            if (this.helpers.GoToScrollPos) {
                console.log('APPLY SCROLL');
                console.log(this.helpers.ScrollYPos);
                window.scrollTo(0, this.helpers.ScrollYPos);

                this.helpers.GoToScrollPos = false;
            }

            // Reinit page functionality
            Page.init();

            if (HTML_CLASSLIST.contains(popstateClass)) {
                HTML_CLASSLIST.remove(popstateClass);
            }
        };

        const start = () => {
            HTML_CLASSLIST.remove('_page-loading');

            enterController(document.body.querySelector('[data-spa-namespace]'), true);

            HTML_CLASSLIST.add('_page-loaded');

            Page.init();

            /**
             * Init Barba
             */
            barba.init({
                schema: {
                    prefix: 'data-spa',
                },
                sync: true,
                debug: false,
                prefetchIgnore: true,
                cacheIgnore: true,
                preventRunning: true,
                requestError: (trigger, action, url, response) => {
                    // go to a custom 404 page if the user click on a link that return a 404 response status
                    if (action === 'click' && response.status && response.status === 404) {
                        barba.go('/404');
                    }

                    // Prevent Barba from redirecting the user to the requested URL
                    // this is equivalent to e.preventDefault() in this context
                    return false;
                },
                prevent: ({ el, event }) => {
                    event.stopPropagation();

                    console.log(el.getAttribute('data-external'));

                    if (el.getAttribute('data-external') != null || el.getAttribute('target') === '_blank') {
                        console.log('External link');
                        return true;
                    }

                    console.log('Internal link');
                    event.preventDefault();
                    return false;
                },
                timeout: 60 * 1000,
                transitions: [{
                    async before(data) {
                        console.log('BEFORE');
                        const done = this.async();

                        beforeController(data, done);
                    },
                    async leave(data) {
                        console.log('LEAVE');
                        const done = this.async();

                        console.log('data');
                        console.log(data);

                         leaveController(data.current.container, done);
                    },
                    async afterLeave(data) {
                        console.log('AFTERLEAVE');
                        const done = this.async();

                        afterLeaveController(data, done);
                    },
                    async enter({ current, next }) {
                        console.log('ENTER');

                        enterController(next.container);
                    },
                    async after({ next }) {
                        console.log('AFTER');

                        afterController();

                        // GA page view tracking
                        if (typeof gtag === 'function') {
                            gtag('event', 'page_view');
                        }
                    }
                }]
            });
        };


        // Window Load
        window.addEventListener('load', e => {
            PageScrollingManager.update(true);
        });

        // DOM load
        document.addEventListener('DOMContentLoaded', e => {
            // Start SPA
            start();

            // Overrides the request class (default is `htmx-request`)
            htmx.config.requestClass = 'loading';

            // Reinit search after swap
            htmx.on('htmx:afterSettle', (event) => {
                // window.reinitSearch();
                Page.init();
            });

            // Show nav
            document.querySelector('.mn-nav').classList.remove('hidden');
        });

        // Popstate
        window.addEventListener('popstate', (event) => {
            // Log the state data to the console
            HTML_CLASSLIST.add(popstateClass);

            // Enable scrolling
            PageScrolling.clearLocks();
        });

    })();
