import mapboxgl from 'mapbox-gl';
class Map {
    constructor() {
        this.build();
    }
    build(){
        let detectMap = document.querySelector('#map');
        console.log(detectMap);

        if (detectMap != undefined){
            console.log("building map");
            mapboxgl.accessToken = 'pk.eyJ1IjoiY2FyZXR0YSIsImEiOiJjbHVlcGxjNnEwdm1yMmpvZmd2ZnU1bnM0In0.vQdNf6IQNc-ucb_6nXV1VA';
            const map = new mapboxgl.Map({
                container: 'map',
                style: 'mapbox://styles/caretta/cluepolzr02g901nwdrce4zg2',
                zoom: 11.5,
                maxZoom: 16,
                minZoom: 11.5,
                center: [-80.07, 26.888212],
                attributionControl: false
            });
            map.addControl(new mapboxgl.NavigationControl());

            map.on('load', () => {
                let buttons = document.getElementById("map__toggle-ui").children;
                for (let button of buttons){
                    button.onclick = function(e){
                        let layerName = this.dataset.layer;
                        for (let otherButton of buttons){
                            let displayString = 'visible';

                            if (otherButton.dataset.layer == layerName){
                                otherButton.classList.add('active');
                                if (layerName != 'all'){
                                    displayString = 'visible';
                                } 
                            } else {
                                otherButton.classList.remove('active');
                                if (layerName != 'all'){
                                    displayString = 'none';
                                } else {
                                    displayString = 'visible';
                                }
                            }

                            if (otherButton.dataset.layer != 'all') map.setLayoutProperty(otherButton.dataset.layer, 'visibility', displayString);  
                        }
                        
                    };
                }
                map.resize();
            });
            
            map.dragRotate.disable();
            map.scrollZoom.disable();
            map.touchZoomRotate.disableRotation();
        }
    }

}
export default new Map();