// Imports
import gsap from 'gsap';

class Home {
	constructor(container) {
        const LOGO = document.querySelector('.home-logo');

		// Show logo
		gsap.to(LOGO, {
            delay: .5,
			duration: .5,
			alpha: 1
		});
	}
}

export default Home
