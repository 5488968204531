// Imports
import Alpine from 'alpinejs';

// Project imports
import PageScrolling from '@js/modules/PageScrolling';
class Navigation {
    constructor() {
        this.nav = document.querySelector('.mn-nav');

        // Set up Alpine store to control nav toggle
        Alpine.store('nav', {
            open: false,

            toggle() {
                if (this.open) {
                    // Close nav
                    this.open = false;
                    // Enable scroll
                    PageScrolling.unlock(this.nav);
                }
                else {
                    // Open nav
                    this.open = true;
                    // Disable scroll
                    PageScrolling.lock(this.nav);
                }
            }
        });

        // Escape key press
        document.onkeydown = (e) => {
            // Escape key maps to keycode `27`
            if (e.key === "Escape") {
                if (Alpine.store('nav').open == true) {
                    Alpine.store('nav').toggle();
                }
            }
        };

        window.onresize = function(event) {
            // Window smaller than breakpoint
            if (window.innerWidth > 1024) {
                if (Alpine.store('nav').open == true) {
                    Alpine.store('nav').toggle();
                }
            }
        };
    }
}

export default new Navigation();



